<template>
  <section data-bs-version="5.1" class="menu menu2 cid-tsNuFQScaR elevation-0" once="menu" id="menu2-0" >
    
    <nav class="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg elevation-0">
        <div class="container-fluid shadow-5">
            <div class="navbar-brand">
                <span class="navbar-caption-wrap"><a class="navbar-caption text-black display-7" >OpenReez</a></span>
            </div>
            <button class="navbar-toggler text-center" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <div class="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav nav-dropdown text-center" data-app-modern-menu="true">
                    <li class="nav-item mb-2 mt-2 me-2">
                        <v-btn variant="text" :to="{ name:'Home'}">
                            Home
                        </v-btn>
                    </li>
                    <li class="nav-item mb-2 mt-2 me-2">
                        <v-btn variant="text" :to="{ name:'Product'}">
                            Our Service
                        </v-btn>
                    </li>
                    <li class="nav-item mb-2 mt-2 me-2">
                        <v-btn variant="text" :to="{ name:'About'}">
                            About US
                        </v-btn>
                    </li>
                </ul>
                
                <!-- <div class="navbar-buttons mbr-section-btn mb-5 mt-5 me-2">
                    <v-btn rounded="pill" color="primary" :to="{ name:'Donate'}">
                        Support US
                    </v-btn>

                </div> -->
            </div>
        </div>
    </nav>
</section>
</template>
<script>
    export default{
        name:'header',
        data(){
            return{

            }
        }
    }
</script>