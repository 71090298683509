<template>
    <!-- Carousel page -->
    <MDBRow class="mb-5">
        <!-- <v-carousel show-arrows="hover" hide-delimiter-background>
            <v-carousel-item
                v-for="(item,i) in items_img"
                :key="i"
                :src="item.src"
                cover >
                    <div class="d-flex fill-height justify-center align-center">
                        <div class="">
                            <h2 style="color: white">{{ item.caption }}</h2>
                        </div>
                    </div>
            </v-carousel-item>
        </v-carousel> -->
        
    </MDBRow>
    <div class="margin text-center">
        <h1>Our Services</h1>
    </div>
    <!-- list page -->
    <MDBContainer class="mt-5 mb-5 justify-content" container>

        <MDBRow>
            <MDBCol md="3">
                        <a href="./validation" class="data-card hover-shadow">
                            <h3>1</h3>
                            <h4>Number Validation</h4>
                            <p>Get info your phone number</p>
                        </a>
            </MDBCol>
            <MDBCol md="3">
                        <a href="./se-image" class="data-card hover-shadow">
                            <h3>2</h3>
                            <h4>SeImage</h4>
                            <p>To get high resolution image</p>
                            
                        </a>
            </MDBCol>
            <MDBCol md="3">
                        <a href="./el-quran" class="data-card hover-shadow">
                            <h3>3</h3>
                            <h4>El-Qur'an</h4>
                            <p>Show all Al-Qur'an surah</p>
                            
                        </a>
            </MDBCol>
            <MDBCol md="3">
                        <a class="data-card hover-shadow" disabled>
                            <h3>4</h3>
                            <h4>Coming</h4>
                            <p>Under develoment project</p>
                            
                        </a>
            </MDBCol>
        </MDBRow>
    </MDBContainer>
</template>

<script>
import { MDBRow, MDBCol, MDBContainer,MDBCarousel,MDBCard,MDBCardBody,MDBCardImg,MDBCardHeader,MDBCardTitle,MDBCardText} from 'mdb-vue-ui-kit';
//import { ref } from 'vue';
    export default{
        components: {
            MDBContainer, MDBRow,MDBCol,MDBCarousel,MDBCard,MDBCardBody,MDBCardImg,MDBCardHeader,MDBCardTitle,MDBCardText
        },
        data(){
            return{
                img_position: 1 ,
                items_img: [
                    {
                        src: "https://mdbootstrap.com/img/Photos/Slides/img%20(15).webp",
                        alt: "...",
                        label: "First slide label",
                        caption: "Nulla vitae elit libero, a pharetra augue mollis interdum."
                    },
                    {
                        src: "https://mdbootstrap.com/img/Photos/Slides/img%20(22).webp",
                        alt: "...",
                        label: "Second slide label",
                        caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    },
                    {
                        src: "https://mdbootstrap.com/img/Photos/Slides/img%20(23).webp",
                        alt: "...",
                        label: "Third slide label",
                        caption: "Praesent commodo cursus magna, vel scelerisque nisl consectetur."
                    }
                ]
            }
        },

        mounted(){
            this.top_Image()
        },
        methods:{
            top_Image(){
                
                //console.log('tes')
            },
        }
    }
</script>

<style>
    @import url('@/assets/card.css');
</style>