<template>
    <div class="container-fluid text-center top-sectionq">
        <MDBRow class="mt-5">
            <MDBCol class="text-center">
                <!-- ads here -->
                <div class="card-body">
                    <!-- ads content -->
                </div>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol md="1"></MDBCol>
            <MDBCol md="10" style="background-color: white;">
                <v-text-field label="Search Surah" variant="outlined" class="mt-5 rounded-pill" v-model="search"></v-text-field>
            </MDBCol>
            <MDBCol md="1"></MDBCol>
        </MDBRow>
    </div>
    <div v-if="loading" class="container text-center loading" >
        <MDBRow class="mt-5" >
            <MDBCol></MDBCol>
        </MDBRow>
        <MDBRow class="mt-5">
            <MDBCol>
                <MDBSpinner color="danger" />
            </MDBCol>
        </MDBRow>
    </div>
    <div v-if="!loading" class="container text-center" >
        <MDBRow class="mt-5" >
            <MDBCol></MDBCol>
        </MDBRow>
        <MDBRow class="mt-5">
            <MDBCol md="4" v-for="lists in filter_data">
                <a :href="url + lists.nomor" class="data-card">
                    <h3>{{lists.nomor}}</h3>
                    <h4>{{lists.nama_latin}}</h4>
                    <p>{{ lists.nama }}</p>
                    <span class="link-text">
                    View
                    <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
                    </svg>
                    </span>
                </a>
            </MDBCol>
        </MDBRow>
    </div>
</template>


<script>
    import { MDBIcon, MDBRow, MDBCol, MDBSpinner } from 'mdb-vue-ui-kit';
    import axios from 'axios';
    import leven from 'leven';

    import api_source from '@/assets/api.json'
    export default{
        components:{
            MDBIcon, MDBRow, MDBCol, MDBSpinner
        },
        mounted(){
            console.log("loading")
            this.load_quran()
        },
        computed:{
            filter_data(){
                if (!this.search) return this.list_quran.filter(lists => lists.nama_latin.toLowerCase().includes(this.search.toLowerCase()))
                
                if (this.search) return this.list_quran.filter(lists => leven(lists.nama_latin.toLowerCase(),this.search.toLowerCase()) <= 3)
            }
        },
        methods:{
            async load_quran(){
                try{
                    const response = await axios.get(api_source.api.quran_url)
                    this.list_quran=response.data
                    console.log("Get Data done")
                    this.loading=false
                }catch(error){
                    console.log(error)
                }
            }
        },
        data(){
            return{
                loading:true,
                list_quran:null,
                url:'./el-quran/surah/',
                search:'',
            }
        },

    }
</script>


<style>
@import url('@/assets/card.css'); 
    .top-sectionq{
        height: 200px;
        max-height: 200px;
        background: rgb(2,0,36);
        background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 44%, rgba(0,212,255,1) 100%);
    }
    .loading{
        height: 200px;
    }

</style>