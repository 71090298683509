import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// import external css
import '@/assets/web/assets/mobirise-icons2/mobirise2.css'

import '@/assets/dropdown/css/style.css'
import '@/assets/socicon/css/styles.css'
import '@/assets/theme/css/style.css'
import '@/assets/mobirise/css/mbr-additional.css'
import '@/assets/parallax/jarallax.css'


// import '@/assets/bootstrap/css/bootstrap.min.css'
// import '@/assets/bootstrap/css/bootstrap-grid.min.css'
// import '@/assets/bootstrap/css/bootstrap-reboot.min.css'

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App).use(router).use(vuetify).mount('#app')
