import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import Product from '../views/services/list.vue'
import AboutView from '../views/AboutView.vue'
import Donate from '../views/Donation/Donate.vue'
import Not_Found from '../components/404.vue'

import services_phone from '../views/services/phone_validation/validation_new.vue'
import services_seimage from '../views/services/image_search/se-image.vue'

import services_quran from '../views/services/el-quran/el-quran.vue'
import view_surah from '../views/services/el-quran/view_surah.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/our-services',
    name:'Product',
    component: Product,
  },
  {
    path: '/validation',
    name: 'phone_validation',
    component: services_phone,
  },
  {
    path: '/se-image',
    name: 'seimage',
    component: services_seimage,
  },
  {
    path: '/el-quran',
    name: 'quran',
    component: services_quran,
  },
  {
    path: '/el-quran/surah/:id',
    name: 'surah',
    component: view_surah,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/donate',
    name: 'Donate',
    component: Donate
  },
  {
    path:'/:pathMatch(.*)*',
    name:'404',
    component: Not_Found
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
