<template>
    <section>
        <v-parallax height="600" :src="require('@/assets/images/background6.jpg')" container>
            <div class="d-flex flex-column fill-height justify-center align-center text-white">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <h1 class="text-h4 font-weight-thin mb-4">
                            <strong>OPENREEZ</strong>
                        </h1>
                        <p class="mbr-text mbr-white mbr-fonts-style display-7">
                            your one-stop-shop for all your online needs. Our brand offers a variety of free tools
                            Say goodbye to the hassle of searching for multiple tools, OPENREEZ has got you covered. Try it out now!
                        </p>
                        <!-- <div class="mbr-section-btn mt-3">
                            <a class="btn btn-danger display-4" href="https://mobiri.se">Create Your Website!</a>
                        </div> -->
                    </div>
                    <div class="col-2"></div>
                </div>
            </div>
        </v-parallax>
    </section>

<section data-bs-version="5.1" class="features1 cid-tsNv2LmIwv" id="features1-2">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9">
                <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                    <strong>Features</strong>
                </h3>
                
            </div>
        </div>
        <div class="row">
            <div class="card col-12 col-md-6 col-lg-3 elevation-0 ms-5 me-5 mt-3 mb-3">
                <div class="card-wrapper">
                    <div class="card-box align-center">
                        <div class="iconfont-wrapper">
                            <span class="mbr-iconfont mobi-mbri-code mobi-mbri"></span>
                        </div>
                        <h5 class="card-title mbr-fonts-style display-7"><strong>Easy to use</strong></h5>
                        <p class="card-text mbr-fonts-style display-7">Just go to ur service menu and you good to go</p>
                    </div>
                </div>
            </div>
            <div class="card col-12 col-md-6 col-lg-3 elevation-0 ms-5 me-5 mt-3 mb-3">
                <div class="card-wrapper">
                    <div class="card-box align-center">
                        <div class="iconfont-wrapper">
                            <span class="mbr-iconfont mobi-mbri-responsive-2 mobi-mbri"></span>
                        </div>
                        <h5 class="card-title mbr-fonts-style display-7"><strong>Free</strong></h5>
                        <p class="card-text mbr-fonts-style display-7">No subscription needed</p>
                    </div>
                </div>
            </div>
            <div class="card col-12 col-md-6 col-lg-3 elevation-0 ms-5 me-5 mt-3 mb-3">
                <div class="card-wrapper">
                    <div class="card-box align-center">
                        <div class="iconfont-wrapper">
                            <span class="mbr-iconfont mobi-mbri-website-theme-2 mobi-mbri"></span>
                        </div>
                        <h5 class="card-title mbr-fonts-style display-7"><strong>Unique tools</strong></h5>
                        <p class="card-text mbr-fonts-style display-7">We have diferent variations of tools</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>
    
    <!-- <v-parallax :src="require('@/assets/images/background3copy.jpg')" style="transform: none !important;">
        <div class="mbr-section-head">
            <h4 class="mbr-section-title mbr-fonts-style align-center display-2" style="margin-top: 85px;">
                <strong>Services</strong>
            </h4>
        </div>
        <MDBRow class="row ms-2 me-2 mt-5 mb-5">
            <MDBCol md="3" class="mt-5 mb-5">
                <MDBCard>
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="https://mdbootstrap.com/img/new/standard/nature/184.webp" top alt="..." />
                    </a>
                    <MDBCardBody class="bg-light">
                        <MDBCardTitle>Card title</MDBCardTitle>
                        <MDBCardText>
                            Mobirise is an easy website builder. Just drop site elements to your page, add content and style it to look the way you like.
                        </MDBCardText>
                        <MDBBtn tag="a" href="#!" color="primary">Explore</MDBBtn>
                    </MDBCardBody>
                </MDBCard>  
            </MDBCol>
            <MDBCol md="3" class="mt-5 mb-5">
                <MDBCard>
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="https://mdbootstrap.com/img/new/standard/nature/184.webp" top alt="..." />
                    </a>
                    <MDBCardBody class="bg-light">
                        <MDBCardTitle>Card title</MDBCardTitle>
                        <MDBCardText>
                            Mobirise is an easy website builder. Just drop site elements to your page, add content and style it to look the way you like.
                        </MDBCardText>
                        <MDBBtn tag="a" href="#!" color="primary">Explore</MDBBtn>
                    </MDBCardBody>
                </MDBCard> 
            </MDBCol>
            <MDBCol md="3" class="mt-5 mb-5">
                <MDBCard>
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="https://mdbootstrap.com/img/new/standard/nature/184.webp" top alt="..." />
                    </a>
                    <MDBCardBody class="bg-light">
                        <MDBCardTitle>Card title</MDBCardTitle>
                        <MDBCardText>
                            Mobirise is an easy website builder. Just drop site elements to your page, add content and style it to look the way you like.
                        </MDBCardText>
                        <MDBBtn tag="a" href="#!" color="primary">Explore</MDBBtn>
                    </MDBCardBody>
                </MDBCard> 
            </MDBCol>
            <MDBCol md="3" class="mt-5 mb-5">
                <MDBCard>
                    <a v-mdb-ripple="{ color: 'light' }">
                        <MDBCardImg src="https://mdbootstrap.com/img/new/standard/nature/184.webp" top alt="..." />
                    </a>
                    <MDBCardBody class="bg-light">
                        <MDBCardTitle>Card title</MDBCardTitle>
                        <MDBCardText>
                            Mobirise is an easy website builder. Just drop site elements to your page, add content and style it to look the way you like.
                        </MDBCardText>
                        <MDBBtn tag="a" href="#!" color="primary">Explore</MDBBtn>
                    </MDBCardBody>
                </MDBCard>  
            </MDBCol>
        </MDBRow>
        <div class="align-center mb-5" style="margin-top: 30px;">
            <MDBBtn color="warning">View More</MDBBtn>
        </div>
        
    </v-parallax> -->


<section data-bs-version="5.1" class="info1 cid-tsNw8DDE4a tes2" id="info1-8" >
    <div class="align-center container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
                <h3 class="mbr-section-title mb-4 mbr-fonts-style display-1">
                    <strong>Our free tools services </strong>
                </h3>
                
                <div class="mbr-section-btn"><a class="btn btn-primary display-4" href="/our-services">See Services</a></div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { MDBCol, MDBRow, MDBCard, MDBCardBody,MDBCardTitle,MDBCardText, MDBBtn,MDBCardImg } from 'mdb-vue-ui-kit';

    export default {
    name: "home",
    data() {
        return {};
    },
    mounted() {
        
    },
    components: { MDBRow, MDBCol, MDBCard,MDBCardBody,MDBCardTitle,MDBCardText,MDBBtn,MDBCardImg }
}
</script>

<style>
    .tes2{
        background-color: #6a22c9;
        color: #6a22c9;
    }
</style>