<template>
    <div class="top-section home container-fluid shadow-lg">
        <MDBRow class="mt-5">
            <MDBCol class="text-center">
                <!-- ads here -->
                <div class="card-body">
                    <!-- ads content -->
                </div>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol md="3"></MDBCol>
            <MDBCol md="6" class="text-center bg-light rounded-7 border" style="margin-bottom: 70px;">
                <h2 class="mb-1" style="margin-top: 30px;">SE-Image</h2>
                <h8 class="mb-5">High Definition Image Searcher</h8>
                <v-text-field 
                    clearable 
                    class="rounded-xl mt-5 ms-3 me-3"
                    label="Search Image"
                    variant="solo"
                    type="text"
                    v-model="cari_gambar"
                ></v-text-field>
                    <v-radio-group class="ms-3" v-model="orient_select">
                        <v-radio label="landscape" value="landscape"></v-radio>
                        <v-radio label="portrait" value="portrait"></v-radio>
                        <v-radio label="squarish" value="squarish"></v-radio>
                    </v-radio-group>
                <v-btn
                rounded="pill"
                color="primary" 
                class="mb-5" @click="btncari"
                aria-controls="collapsibleContent1"
                :disabled="!cari_gambar" >
                    Search
                </v-btn>    
            </MDBCol>
            <MDBCol md="3"></MDBCol>
        </MDBRow>
    </div>
    <MDBCollapse id="collapsibleContent1" v-model="hasilcolspans" class="container mt-5 mb-5 text-center">
        <MDBSpinner v-if="loading" color="success" />
        <MDBRow v-if="!loading">
            <MDBCol md="3" v-for="image in images" fluid>
                <img class="mt-1 mb-1 hover-shadow img-fluid rounded-4" :src="image.urls.regular" alt=""/>
            </MDBCol>
        </MDBRow>
    </MDBCollapse>
</template>

<style>
    .top-section{
        background: rgb(2,0,36);
        background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 44%, rgba(0,212,255,1) 100%);
    }
</style>

<script>
    import { MDBInput, MDBBtn, MDBIcon, MDBContainer,MDBRow , MDBCol, MDBCollapse, MDBSpinner} from 'mdb-vue-ui-kit';
    import axios from 'axios';
    import api_source from '@/assets/api.json'

    export default{
        name:'se-image service',
        components:{
            MDBInput, MDBBtn, MDBIcon, MDBContainer,MDBRow , MDBCol, MDBCollapse, MDBSpinner
        },
        methods:{
            btncari(){
                this.loading=true

                this.hasilcolspans = true
                this.get_image_data(this.cari_gambar,this.orient_select)
            },
            async get_image_data(query,orient){
                try{
                    const response = await axios.get(api_source.api.img_url,{
                        params : {
                            client_id: api_source.key,
                            query:query,
                            per_page:900,
                            orientation: orient,
                        }
                    })
                    console.log(response.data.results)
                    this.images=response.data.results
                    this.loading=false
                }catch(error){
                    console.log(error)
                }
            }
        },
        data(){
            return{
                cari_gambar:null,
                orient_select:'landscape',

                hasilcolspans:false,
                loading:true,
                images:null,
                
            }
        },
        
        
    }
</script>