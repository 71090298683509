<template>
  <div class="margin text-center">
    <h1>ABOUT US</h1>
  </div>
  <MDBContainer>
    <MDBRow>
      <MDBCol md="1"></MDBCol>
      <MDBCol md="10" class="justify-content">
        <h4 class="">
          OPENREEZ the ultimate web tool, designed with you in mind. Our tool is not only completely 
          free to use, but it's also incredibly easy. With just a few clicks, you can access the features you 
          need. Whether you're a beginner or a tech-savvy pro, our tool is designed to make your life easier. 
          Try it now and experience the convenience of our user-friendly interface and seamless functionality. 
          Upgrade your online experience with our web tool today!
        </h4>
      </MDBCol>
      <MDBCol md="1"></MDBCol>
    </MDBRow>
  </MDBContainer>
  <div class="container-fluid button-countainer">
    <MDBRow>
      <MDBCol class="button-section position-relative position-relative-example">
        <h3 class="text text-center position-absolute top-50 start-50 translate-middle">
          <strong>
            Because every problem you might have now, can be our next challenge.
          </strong>
        </h3>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script>
import { MDBContainer, MDBRow, MDBCol } from 'mdb-vue-ui-kit';
  export default{
    components:{
      MDBContainer, MDBRow, MDBCol
    }
  }
</script>

<style>
  .margin{
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .button-section{
    margin-top: 60px;
    margin-bottom: 60px;
    height: 130px;
  }
  .button-countainer{
    background-color: #030099; 
    color: #030099; 
    margin-top: 80px;
  }
  .text{
    color: white;
    font-size: 2.5em;
  }
</style>