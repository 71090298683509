<template>
  <Header></Header>
  <router-view/>
  <bawah></bawah>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Header from '@/components/top-navbar.vue'
import bawah from '@/components/button-footer.vue'


export default {
  name: 'App',
  components: {
    HelloWorld,
    Header,
    bawah
  }
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
