<template>
    <section data-bs-version="5.1" class="footer3 cid-tsNwd6cmUm" once="footers" id="footer3-9">
    <div class="container">
        <div class="media-container-row align-center mbr-white">
            <div class="row row-links">
                <ul class="foot-menu">
                    <li class="foot-menu-item mbr-fonts-style display-7">
                        <button class="text-white" href="#" target="_blank">About us</button>
                    </li>
                    <li class="foot-menu-item mbr-fonts-style display-7">
                        <button class="text-white" href="#" target="_blank">Services</button>
                    </li>
                </ul>
            </div>
           
            <div class="row row-copirayt">
                <p class="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
                    © Copyright 2023 OpenReez. All Rights Reserved.
                </p>
            </div>
        </div>
    </div>
</section>
</template>

<script>
    export default{
        name:'header',
        data(){
            return{

            }
        }
    }
</script>