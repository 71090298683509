<template>
    <div class="top-section home container-fluid shadow-lg">
        <MDBRow class="mt-5 mb-3">
            <MDBCol class="text-center">
                <!-- ads here -->
                <div class="card-body">
                    <!-- ads content -->
                </div>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol md="3"></MDBCol>
            <MDBCol md="6" class="text-center bg-light rounded-7 border" style="margin-bottom: 70px;">
                <h2 class="mb-5" style="margin-top: 30px;">Phone Number Validation Tool</h2>
                <v-text-field class="rounded-xl mt-5 ms-3 me-3" variant="solo" clearable label="Input Phone Number" placeholder="example : 83xxxxxxxxx" type="number" v-model="input_phone" ></v-text-field>
                <v-select
                    density="compact"
                    class="rounded-xl ms-3 me-3"
                    v-model="input_country"
                    transition="scale-transition"
                    clearable
                    label="Select Country"
                    :items="country_list_option"
                    variant="solo"
                    item-title="name"
                    item-value="iso">
                </v-select>
                <MDBBtn v-if="notHide" class="mb-5" color="primary" @click="check_number()" aria-controls="collapsibleContent2" :disabled="!input_phone || input_country==null" rounded>Check Phone Number</MDBBtn>
                <MDBBtn v-if="!notHide" class="mb-5" color="secondary" @click="reset()" :disabled="loading & !error">Reset</MDBBtn>
            </MDBCol>
            <MDBCol md="3"></MDBCol>
        </MDBRow>
    </div>
    <div>
        <MDBRow>
            <MDBCol md="3"></MDBCol>
            <MDBCol md="6">
                <MDBCollapse class="text-center mt-5 mb-5" id="collapsibleContent2" v-model="collapse1">
                    <div v-if="loading & !error" style="height: 200px;">
                        <MDBSpinner v-if="loading & !error" class="mt-2"></MDBSpinner>
                    </div>
                    <div v-if="!(loading & !error)" class="result-section">
                        <h2 v-if="error">Not Found</h2>
                        <h2 v-if="error">Please insert phone number properly</h2>
                        <MDBCollapse v-if="!error" class="mt-5" v-model="collapse2">
                            <div class="mt-2 mb-5">
                                <h3 class="mt-1 mb-2">Result</h3>
                            </div>
                            <div>
                                <p class="mb-5">Your input phone number : {{phone_number}}</p>
                            </div>
                            <v-table class="text-start mb-5 rounded-xl border">
                                <thead>
                                    <tr class="table-primary">
                                        <th scope="col" class="">MAIN</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    <tr>
                                        <th scope="row">Carrier Name:</th>
                                        <td>{{carrier}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Phone Number:</th>
                                        <td>{{number}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mobile Country Code:</th>
                                        <td>{{mobile_code}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Country:</th>
                                        <td>{{country}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Time Zone:</th>
                                        <td>{{timezone}}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <v-table class="text-start mb-5 rounded-xl border">
                                <thead>
                                    <tr class="table-primary">
                                        <th scope="col" class="">FORMAT</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    <tr>
                                        <th scope="row">E164:</th>
                                        <td>{{E164}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">International:</th>
                                        <td>{{inter}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">National:</th>
                                        <td>{{nation}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">RFC3966:</th>
                                        <td>{{RFC3966}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Out-of-country format from US:</th>
                                        <td>{{ooc_format}}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <h2><strong>{{number}}</strong> is a <strong v-if="isValid">VALID</strong> <strong v-if="!isValid">NOT VALID</strong></h2>
                        </MDBCollapse>
                    </div>
                    
                </MDBCollapse>
            </MDBCol>
            <MDBCol md="3"></MDBCol>
        </MDBRow>
    </div>
    
</template>

<style>
    .top-section{
        background: rgb(2,0,36);
        background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 44%, rgba(0,212,255,1) 100%);
    }
    .result-section{
        margin-top: 50px;
        margin-bottom: 50px;
    }
</style>

<script>
    import { MDBInput, MDBBtn, MDBIcon, MDBContainer,MDBRow , MDBCol, MDBCollapse, MDBSpinner} from 'mdb-vue-ui-kit';
    import { ref } from 'vue';
    import axios from 'axios';
    import json from '@/assets/counrycode.json'
    import api_source from '@/assets/api.json'


    const search1 = ref('');
    const collapse1 = ref(false);
    const collapse2 = ref(false);
    const input_phone = ref('');
    const country_list_option = ref('');
    export default {
        name:'HomePage',
        data(){
            return{
                notHide: true,
                phone_number: '0',
                data:null,
                loading:true,
                error:false,
                isloaded:false,
                errorMsg:'',

                country:null,
                timezone:null,
                carrier:null,
                mobile_code:null,
                isValid:null,
                number:null,

                E164:null,
                inter:null,
                nation:null,
                RFC3966:null,
                ooc_format:null,

                country_list_option:json.data.countryCodes,
                input_country:null,
            }
        },
        components: {
            MDBInput,
            MDBBtn,
            MDBIcon,
            MDBContainer,
            MDBRow,
            MDBCol,
            MDBCollapse,
            MDBSpinner,
            
        },
        mounted() {
            this.reset()
            //this.option_select_country()
            //console.log(this.country_list_option.PromiseResult)
        },
        methods:{
            reset(){ 
                if(this.collapse1==true){
                    this.collapse1=false
                    this.collapse2=false
                    this.error=false
                    this.notHide=true
                    this.loading=true
                    this.input_country = null;
                    this.phone_number=''
                    this.input_phone=''
                    //this.$router.go()
                }
            },
            //call method check_number_call
            check_number(){
                this.notHide=false
                this.collapse1=!this.collapse1
                this.phone_number=this.input_phone
                let iso=null
                if(this.input_country=='Select Country'){
                    iso=null
                }else{
                    iso=this.input_country
                }
                this.check_number_call(this.phone_number,iso)
            },
            //api for validation phone number
            async check_number_call(phone,c_iso){
                try {
                    const response = await axios.get(api_source.api.valid_url,{
                        params: {
                            number: phone,
                            country: c_iso
                        },
                        headers: {
                            'X-RapidAPI-Key': api_source.valid_key,
                            'X-RapidAPI-Host': api_source.valid_host
                        }
                    });
                    this.data = response.data;
                    console.log(this.data)

                    this.carrier=this.data.carrier
                    this.country=this.data.location
                    let timezone=(this.data.timeZone_s).replace("["," ").replace("]"," ")
                    this.timezone=timezone
                    this.mobile_code="+" + this.data.countryCode
                    this.isValid=this.data.isValidNumber
                    this.number=this.mobile_code + " " +this.data.nationalNumber

                    this.E164=(this.number).replace(" ","")
                    this.inter=this.data.internationalFormat
                    this.nation=this.data.nationalFormat
                    this.RFC3966="tel: "+(this.inter).replace(" ","-")
                    this.ooc_format=this.data.outOfCountryFormatFromUS

                    this.loading=false
                    this.error = false;
                    this.collapse2=true
                } catch (error) {
                    this.errorMsg = error;
                    this.error = true;
                    console.log(this.errorMsg)
                }
            }
        },
        setup() {
             
            return {
                search1,
                collapse1,
                collapse2,
                input_phone,
                
            };
        },
    };
</script>