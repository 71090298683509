<template>
    <div v-if="surah_loading" class="container-fluid">
        <MDBRow class="mt-5 text-center">
            <MDBCol style="height: 500px;">
                <MDBSpinner color="success" style="margin-top: 170px;"/>
            </MDBCol>
        </MDBRow>

    </div>
    <div v-if="!surah_loading" class="container-fluid" style="background-color:#c5defc;">
        <MDBRow class="mt-5">
            <MDBCol style="margin-left: 50px; margin-right: 50px;">
                <div class="card shadow-3 rounded-7 mt-5 mb-5" style="background-color: white;">
                    <div class="card-body">
                        <h3 class="card-title text-end me-5">{{verses.data.nama_latin}} • {{ verses.data.nama }}</h3>
                        <v-divider></v-divider>
                        <p class="card-text ms-5">{{ verses.data.tempat_turun }} • {{ verses.data.jumlah_ayat }} Verses </p>
                        <!-- <v-divider></v-divider>
                        <audio class="mt-2 mb-2" controls>
                            <source src="verses.data.audio" type="audio/mpeg"/>
                        </audio> -->
                    </div>
                </div>
            </MDBCol>
        </MDBRow>
        <MDBRow v-for="verse in verses.data.ayat">
            <MDBCol style="margin-left: 50px; margin-right: 50px;">
                <div class="card shadow-3 rounded-7 mb-5" style="background-color: white;">
                    <div class="card-body">
                        <p class="card-title ms-5">{{ verse.surah }} :{{ verse.nomor }}</p>
                        <v-divider></v-divider>
                        <h1 class="card-text text-end me-5 mb-5">{{ verse.ar }}</h1>
                        <p class="card-text ms-5 mt-3">{{ verse.tr }}</p>
                    </div>
                </div>
            </MDBCol>
        </MDBRow>
        <MDBRow style="margin-left: 50px; margin-right: 50px;">
            <MDBCol class="mb-5" md="3">
                <v-btn
                    :href="verses.data.surat_sebelumnya.id"
                    size="x-large"
                    color="success"
                    v-if="prev">
                    <i class="fa-solid fa-backward me-3"></i> {{verses.data.surat_sebelumnya.nama_latin}} • {{ verses.data.surat_sebelumnya.nama }}
                </v-btn>
            </MDBCol>
            <MDBCol md="6"></MDBCol>
            <MDBCol class="mb-5" md="3" >
                <v-btn
                    :href="verses.data.surat_selanjutnya.id"
                    size="x-large"
                    color="success"
                    v-if="next">
                    {{verses.data.surat_selanjutnya.nama_latin}} • {{ verses.data.surat_selanjutnya.nama }} <i class="fa-solid fa-forward ms-3"></i>
                </v-btn>
            </MDBCol>
        </MDBRow>
    </div>
</template>

<script>
    import { MDBRow, MDBCol, MDBSpinner } from 'mdb-vue-ui-kit';
    import axios from 'axios';
    import api_source from '@/assets/api.json'
    export default{
        components:{
            MDBRow, MDBCol, MDBSpinner
        },
        mounted(){
            this.load_surah()
        },
        methods:{
            async load_surah(){
                try {
                    const response = await axios.get(api_source.api.surah_url + this.number_of_surah)
                    this.verses=response
                    console.log(response)
                    if(!this.verses.data){
                        console.log('data null, redirect to list quran')
                        this.$router.push({name: 'quran'})
                    }else{
                        if(this.verses.surat_selanjutnya == false){
                            this.next=false
                        }else{
                            this.next=true
                        }


                        if(this.verses.data.surat_sebelumnya == false){
                            this.prev=false
                        }else{
                            this.prev=true
                        }
                        this.surah_loading=false
                    }

                } catch (error) {
                    console.log(error)
                }
            }
        },
        data(){
            return{
                number_of_surah:this.$route.params.id,
                surah_loading:true,
                verses:null,

                next:false,
                prev:false,
            }
        }
    }
</script>

<style>

</style>